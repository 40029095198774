import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const InquiryModal = ({ setOpenInquiryModal, inquiryModalData,setShowNotification }) => {
  
  console.log(inquiryModalData)

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const notify = () => toast.success('Thanks for reaching out. We will reach out to you shortly', {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    // transition: Bounce,
    });
    const notifyError=()=>{
      toast.error('Something went wrong. Please try again', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
        });
    }
    const templateParams = {
      from_name: formData.name,
      email: formData.email,
      message: formData.message,
      phoneNumber: formData.phoneNumber,
      productname: inquiryModalData?.productName // Include the hardcoded product name here
    };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.message
    ) {
      alert("Please fill in all fields.");
      return;
    }
    if (!isValidEmail(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }
    // You can add additional val
    emailjs
      .send(
        "service_ji8cdyb",
        "template_g33y33z",
        templateParams,
        "KwDrGjOkH_kargRDn"
        
      )
      .then(
        (result) => {
          notify();
          setTimeout(()=>{
            setFormData({
              name: "",
              email: "",
              phoneNumber: "",
              message: "",
            });
            setOpenInquiryModal(false);
          },4000)
         
         
        },
        (error) => {
          console.log("fail", error);
          notifyError();
          setTimeout(()=>{
            setFormData({
              name: "",
              email: "",
              phoneNumber: "",
              message: "",
            });
            setOpenInquiryModal(false);
          },4000)
      
        }
      );
    // Reset form data
   
   
  };
  const isValidEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const handlePopup = () => {
    setOpenInquiryModal(false);
  };



  return (
    <div>
      <ToastContainer/>
      <div
        className="fixed z-30 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-3/5 md:w-2/4 lg:max-w-screen-xl md:max-w-screen-xl max-w-full w-full p-6">
            <div
              id="video_close_btn"
              className="absolute opacity-100 cursor-pointer z-30 top-2 right-2 text-shark-500 p-2"
              aria-hidden="true"
              onClick={() => handlePopup()}
              style={{
                fontSize: "45px",
                lineHeight: "30px",
                transform: "rotate(45deg)",
              }}
            >
              <span>+</span>
            </div>
            <div>
              <p className="lg:text-2xl md:text-2xl text-shark-400 pb-4 font-medium max-w-6xl">
                Enquiry Form
              </p>
              <div className="flex-col place-items-center md:place-items-start pb-8 hidden md:block lg:block">
                <hr className="w-40 border-b-2 border-green bg-green" />
              </div>
            </div>
            <div className="grid lg:grid-cols-2 lg:gap-4 gap-6">
              <form
                id="contact-form"
                // method="POST"
                className="grid grid-cols-1 gap-4 md:grid-cols-1 md:gap-5 lg:grid-cols-2 lg:gap-6"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="sm:col-span-2">
                  <div className="mt-1 relative shadow-sm">
                    <input
                      placeholder="Name *"
                      id="name"
                      name="name"
                      onChange={handleChange}
                      value={formData.name}
                      required
                      className="rounded-md bg-transparent form-input py-3 px-4 block w-full order-green focus:border-lightGreen border-2 border-solid  focus:outline-none transition ease-in-out duration-150"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <input
                    placeholder="Email *"
                    id="emailId"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="rounded-md bg-transparent form-input py-3 px-4 block w-full order-green focus:border-lightGreen border-2 border-solid  focus:outline-none transition ease-in-out duration-150"
                  />
                </div>

                <div className="sm:col-span-2">
                  <div className="flex flex-wrap">
                    <div className="mt-1 relative  shadow-sm w-full">
                    <input
                    type="number"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    // value={formData.phoneNumber}
                    placeholder="Your Phone Number"
                    onChange={handleChange}
                    maxLength={10}
                    className="rounded-md bg-transparent form-input py-3 px-4 block w-full order-green focus:border-lightGreen border-2 border-solid  focus:outline-none transition ease-in-out duration-150"
                    required
                  />
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-2 mt-1 relative shadow-sm">
                  <textarea
                    placeholder="Message"
                    id="message"
                    name="message"
                    onChange={handleChange}
                    value={formData.message}
                    rows="2"
                    // required
                    className="rounded-md bg-transparent form-input py-3 px-4 block w-full order-green focus:border-lightGreen border-2 border-solid  focus:outline-none transition ease-in-out duration-150"
                  ></textarea>
                </div>
                <div className="sm:col-span-2">
                  <span className=" inline-flex shadow-sm">
                    <div className=" mt-5 inline-flex ">
                      <button
                        type="submit"
                        className="bg-green relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-lightGreen rounded"
                      >
                        Submit
                      </button>
                    </div>
                  </span>
                </div>
              </form>
              <div className="lg:block hidden justify-center items-center self-center">
                <img
                  className="lg:h-auto lg:w-full md:w-full md:h-auto w-full h-auto object-cover"
                  src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/08/New-Illustration.png"
                  alt="Support team"
                  title="Support team"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryModal;
