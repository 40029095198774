import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Hasenberg from "../../assets/MobileHasenberg";
import { Close } from "../../assets/close";
import InquiryModal from "../InquiryModal";
import logo from "../../assets/logo.svg";
import logowhite from "../../assets/white.svg";
import { productData } from "../../helpers/constants";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSlideOutVisible, setIsSlideOutVisible] = useState(false);
  const [isOpenInquiryModal, setOpenInquiryModal] = useState(false);
  const [isProductsDropdownOpen, setProductsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleOverlayClick = () => {
    setIsSlideOutVisible(false);
  };
  const handleInquiryClick = () => {
    setOpenInquiryModal(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProductsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const scrollToBottom = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    if (window.innerWidth >= 768) {
      const offset = scrollHeight * 0.9 - windowHeight; // Adjust this value as needed
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    } else {
      const offset = scrollHeight * 0.8 - windowHeight; // Adjust this value as needed
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }


  };

  return (
    <div className="fixed top-0 w-full z-30">
      {isOpenInquiryModal && (
        <InquiryModal setOpenInquiryModal={setOpenInquiryModal} />
      )}
      <div className="flex shadow-lg bg-white z-50  justify-between  items-center py-1  md:py-3  lg:py-3 px-2 md:px-3 lg:px-3">
        <div>
          <img
            src={logo}
            alt="Jigrra Logo"
            onClick={() => navigate("/")}
            className="w-24 md:w-28 lg:w-28 h-12 cursor-pointer"
          />
        </div>
        <div>
          <ul className="hidden md:flex items-center">
            <li className=" md:px-5 lg:px-6  ">
              <NavLink
                to="#"
                className={`md:font-medium relative ${location.pathname === "/" ? "text-green" : ""
                  }`}
              >
                Home
                <span className="absolute bottom-[5%] left-1/2 bg-green w-0 h-0 transition-all duration-300"></span>
              </NavLink>
            </li>
            <li className="relative md:px-5 lg:px-6 lg:mx-3" ref={dropdownRef}>
              <span className="md:font-medium cursor-pointer" onClick={() => setProductsDropdownOpen(!isProductsDropdownOpen)}>
                Products ▼
              </span>
              {isProductsDropdownOpen && (
                <ul className="absolute left-0 mt-2 w-48 bg-white shadow-md border border-gray-200 rounded-lg overflow-hidden z-50">
                  {productData.map((product, index) => (
                    <li key={index}>
                      <Link
                        to={{
                          pathname: `/products/${product?.productPath}`, // Assuming your detail page route is /product/:id
                          state: { productData: product }, // Pass the product data in the state object
                        }}
                        className="block px-4 py-2 hover:bg-green hover:text-white"
                        onClick={() => setProductsDropdownOpen(false)}
                      >
                        {product.productName}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li className=" md:px-5 lg:px-6 lg:mx-3">
              <NavLink
                to="#"
                className={`md:font-medium  relative ${location.pathname === "/about"
                  ? "text-green border-b-[#e2d258]"
                  : ""
                  }`}
                onClick={scrollToBottom}
              >
                About Us
              </NavLink>
            </li>
            <li className=" md:px-5 lg:px-6 lg:mx-3">
              <NavLink
                to="#"
                className={`md:font-medium relative ${location.pathname === "/contact" ? "text-green" : ""
                  }`}
                onClick={scrollToBottom}
              >
                Contact us
              </NavLink>
            </li>
            <li>
              <span className="cursor-pointer" onClick={handleInquiryClick}>
                {/* <ContactSvg /> */}
                <button className="bg-green hover:bg-white font-semibold  text-white hover:text-green px-5 border-2 border-[#47b200] py-2 rounded-2xl">
                  Enquiry Now
                </button>
              </span>
            </li>
          </ul>
          <div className="hidden md:flex"></div>
        </div>

        <div className="block md:hidden">
          {isSlideOutVisible ? (
            <Close onClick={() => setIsSlideOutVisible(!isSlideOutVisible)} />
          ) : (
            <Hasenberg
              onClick={() => setIsSlideOutVisible(!isSlideOutVisible)}
            />
          )}
        </div>
        <div
          className={`fixed inset-y-0 left-0 z-50 w-2/3  bg-green transition-transform duration-300 ${isSlideOutVisible ? "translate-x-0" : "-translate-x-full"
            }`}
        >
          <ul className="">
            <NavLink to="/">
              <img
                src={logowhite}
                alt="Jigrra Logo"
                onClick={() => navigate("/")}
                className="w-28 md:w-28 lg:w-32 h-12"
              />
            </NavLink>
            <NavLink
              to="#"
              className={`md:font-medium ${location.pathname === "/" ? "" : ""
                } text-white font-semibold`}
              onClick={() => setIsSlideOutVisible(false)}
            >
              <li
                className={`py-3  px-3 ${location.pathname === "/" ? "bg-secondary" : ""
                  } `}
              >
                Home
              </li>
            </NavLink>
            <li className="relative py-3 px-3" ref={dropdownRef}>
              <span className="md:font-medium cursor-pointer text-white" onClick={() => setProductsDropdownOpen(!isProductsDropdownOpen)}>
                Products ▼
              </span>
              {isProductsDropdownOpen && (
                <ul className="absolute left-0 mt-2 w-48 bg-white shadow-md border border-gray-200 rounded-lg overflow-hidden z-50">
                  {productData.map((product, index) => (
                    <li key={index}>
                      <Link
                        to={`/products/${product?.productPath}`}
                        className="block px-4 py-2 hover:bg-green hover:text-white"
                        onClick={() => {
                          setProductsDropdownOpen(false);
                          setIsSlideOutVisible(!isSlideOutVisible);
                        }}
                      >
                        {product.productName}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li
              className={`py-3  px-3 ${location.pathname === "/about" ? "bg-secondary" : ""
                } `}
              onClick={scrollToBottom}
            >
              <NavLink
                to="#"
                className={`md:font-medium ${location.pathname === "/about" ? "" : "text-white"
                  } text-white font-semibold`}
                onClick={() => setIsSlideOutVisible(false)}
              >
                About Us
              </NavLink>
            </li>
            <li
              className={`py-3  px-3 ${location.pathname === "/contact" ? "bg-secondary" : ""
                } `}
              onClick={scrollToBottom}
            >
              <NavLink
                to="#"
                className={`md:font-medium ${location.pathname === "/contact" ? "" : "text-white"
                  } text-white font-semibold`}
                onClick={() => setIsSlideOutVisible(false)}
              >
                Contact us
              </NavLink>
            </li>
          </ul>
        </div>
        {isSlideOutVisible && (
          <div
            className="fixed inset-0 z-40 bg-black bg-opacity-50 "
            onClick={handleOverlayClick}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Header;
