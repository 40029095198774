import "./App.css";
import { lazy, Suspense, useEffect } from "react";
import Layout from "./components/Layout";
import AOS from "aos";
import "aos/dist/aos.css";
import { Routes, Route } from "react-router-dom";
const Home = lazy(() => import("./pages/Home"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const NotFound = lazy(() => import("./pages/NotFound"));
const ProductDetail = lazy(() => import("./pages/ProductDetail"));

function App() {
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: false,
      offset: 100,
      duration: 700,
      easing: "ease-out-quad",
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      setTimeout(() => {
        AOS.refresh();
      }, 500);
    }
  });
  return (
    <Suspense fallback={<div className="text-center py-10">Loading...</div>}>
      <Routes>
        <Route element={<Layout />}>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/products/:name" element={<ProductDetail />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
