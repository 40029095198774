import JigraaSugarCrush from "../assets/JigrraFront.jpg";
import JigrraChillPro from "../assets/jigrrachillpro.jpeg";
import JigrraCoolChill from "../assets/jigrracool-chill.jpeg";
export const productData = [
  {
    id: 1,
    productPath: "jigrracrush",
    productTitle: "JigrraCrush - Sugarcane Juice Machine",
    detail:
      "We are the trusted sugarcane juice machine manufacturer in India making machines that are hyenic, easy to use and made for diverse industries.",
    image: JigraaSugarCrush,
    price: 47200,
    productSubtitle: "Instant Sugarcane Juice within a minute",
    productName: "JigrraCrush",
    keyFeatures: [
      "Compact design suitable for placement in front of customers.",
      "Consistently delivers fresh, pure, and hygienic juice.",
      "Rollers made of food-grade stainless steel for durability and hygiene, unlike plastic alternatives.",
      "Rust-free and sturdy construction requiring minimal support or upkeep.",
      "Saves on overhead costs by minimizing maintenance expenses.",
      "Doesn't require periodic lubrication, unlike other machines on the market.",
      "Very economical, consuming as low as 1/2 HP (400 Watts) resulting in lower electricity bills.",
      "Consideration of this point is crucial before purchasing, as it signifies earning more rather than spending more.",
    ],
    metaTitle: "Compact Sugarcane Juice Machine - JigrraCrush",
    metaDescription: "JigrraCrush - A small size sugarcane juice machine that gives you fresh, hygienic juice in less than a minute with low maintenance, and energy efficiency.",
    technicalDescription: [
      { label: "Motor Power", value: "0.5 HP" },
      { label: "Capacity (glass/hr)", value: "400 glass/hr" },
      { label: "Automation Grade", value: "Automatic" },
      { label: "Yield", value: "300 ml/kg" },
      { label: "Warranty", value: "2 Year" },
      { label: "Material", value: "SS" },
      { label: "Usage/Application", value: "Commercial" },
      { label: "Phase", value: "Single Phase" },
      { label: "Voltage", value: "240V" },
      { label: "Brand", value: "Jigrra" },
    ],
    keywords: [
      "sugarcane juice machine", "juice extractor", "sugarcane crusher", "sugarcane machine manufacturer", "hygienic juice machine"
    ],
    modelNumber:"JSJ01"
  },
  {
    id: 2,
    productPath: "jigrracool",
    productTitle: "Jigrra Cool - Sugarcane Juice Machine with Ice Box",
    image: JigrraCoolChill,
    price: 75000,
    productSubtitle: "Instant Sugarcane Juice within a minute",
    productName: "Jigrra Cool",
    keyFeatures: [
      "Core made of SS304 and structure of SS202",
      "Single panel operating system",
      "Whole machine on wheels"
    ],
    metaTitle: "Sugarcane Juice Machine with Ice Box - Jigrra Cool",
    metaDescription: "Jigrra Cool - A sugarcane juice machine with an in-built ice box for instantly fresh and chilled juice, ensuring hygiene, efficiency, and convenience.",
    technicalDescription: [
      { label: "Ice Box Capacity", value: "12 Kg" },
      { label: "Voltage", value: "220 V - 50 Hz" },
      { label: "Total Power", value: "600 Watt" },
      { label: "Power Consumption", value: "5 Rs/hr" },
      { label: "Size", value: "35.5*23.7*45*5" },
      { label: "Weight", value: "90 Kg approx" },
      { label: "Capacity", value: "250-300 glass/hour" },
      { label: "Juice Storage Capacity", value: "1 Ltr" },
      { label: "Waste Bin Capacity", value: "35 Kg" },
      { label: "Automation Grade", value: "Automatic" },
      { label: "Packing Type", value: "Armor & Bubble Packing" },
    ],
    keywords:[],
    modelNumber:"JSJ02"
  },
  {
    id: 3,
    productPath: "jigrrachill",
    productTitle: " Jigrra Chill - Sugarcane Juice Machine with Instant Chilling System",
    image: JigrraCoolChill,
    price: "-",
    productSubtitle: "Instant Sugarcane Juice within a minute",
    productName: "Jigrra Chill",
    keyFeatures: [
      "Machine overheating fan",
      "Core made of SS304 and structure made of SS202",
      "Single panel operating system",
      "Whole machine on wheels"
    ],
    metaTitle: "Sugarcane Juice Machine with Instant Chilling System - Jigrra Chill ",
    metaDescription: "Jigrra Chill - A sugarcane juice machine with an advanced chilling system to deliver chilled, fresh and hygienic juice in no time.",
    technicalDescription: [
      { label: "Voltage", value: "220 V - 50 Hz" },
      { label: "Total Power", value: "1200 Watt" },
      { label: "Chilling System", value: "800 Watt" },
      { label: "Power Consumption", value: "5 Rs/hr" },
      { label: "Size Without Canopy", value: "35.5*23.7*45*5" },
      { label: "Size With Canopy", value: "35.5*23.7*72" },
      { label: "Weight without Canopy", value: "115 Kg approx" },
      { label: "Weight with Canopy", value: "130 Kg approx" },
      { label: "Capacity", value: "250-300 glass/hour" },
      { label: "Juice Storage Capacity", value: "1 Ltr" },
      { label: "Waste Bin Capacity", value: "35 Kg" },
      { label: "Automation Grade", value: "Automatic" },
      { label: "Packing Type", value: "Armor & Bubble Packing" },
    ],
    keywords:[],
    modelNumber:"JSJ03"
  },
  {
    id: 4,
    productPath: "jigrrachill-pro",
    productTitle: "Jigrra Chill Pro - Sugarcane Juice Machine with Instant Chilling System",
    image: JigrraChillPro,
    price: "-",
    productSubtitle: "Instant Sugarcane Juice within a minute",
    productName: "Jigrra Chill Pro",
    keyFeatures: [
      "3 steps glass storage",
      "Bowl and glass stand on surface of machine",
      "Roof lights",
      "Cash counter",
      "Machine overheating fan",
      "Core made of SS304 and structure made of SS202",
      "Single panel operating system",
      "Whole machine on wheels"
    ],
    metaTitle: "Sugarcane Juice Machine with Instant Chilling System - Jigrra Chill Pro",
    metaDescription: "Jigrra Chill Pro - A fully equipped sugarcane juice machine with advanced chilling system to provide chilled, fresh and hygenic juice instantly.",
    technicalDescription: [
      { label: "Voltage", value: "220 V - 50 Hz" },
      { label: "Total Power", value: "1200 Watt" },
      { label: "Chilling System", value: "800 Watt" },
      { label: "Power Consumption", value: "8 Rs/hr" },
      { label: "Size Without Canopy", value: "37.5*55*45" },
      { label: "Size With Canopy", value: "37.5*55*76" },
      { label: "Weight without Canopy", value: "150 Kg approx" },
      { label: "Weight with Canopy", value: "170 Kg approx" },
      { label: "Capacity", value: "250-300 glass/hour" },
      { label: "Juice Storage Capacity", value: "2 Ltr" },
      { label: "Waste Bin Capacity", value: "80 Kg" },
      { label: "Automation Grade", value: "Automatic" },
      { label: "Packing Type", value: "Armor & Bubble Packing" },
    ],
    keywords:[],
    modelNumber:"JSJ04"
  },
];